import './FeaturedShops.css';
import AloiseImage from '../../assets/images/aloise.png';
import MegatoneImage from '../../assets/images/megatone.png';
import NuevasImage from '../../assets/images/nuevas.png';
import PardoImage from '../../assets/images/pardo.png';

const FeaturedShops = () => {


    return (
        <div className="featured-shops" id='featuredShops'>
            <div className="title-featured-shop">Comercios destacados</div>
            <div className="yolo-images">
                <article>
                    <img src={AloiseImage} alt="YOLO" className="yolo-image" />
                </article>
                <article>
                    <img src={MegatoneImage} alt="YOLO" className="yolo-image-megatone" />
                </article>
                <article>
                    <img src={NuevasImage} alt="YOLO" className="yolo-image-nuevas" />
                </article>
                <article>
                    <img src={PardoImage} alt="YOLO" className="yolo-image" />
                </article>
            </div>
        </div>
    );
};

export default FeaturedShops;