import '../App.css';
import InstagramBlueIcon from '../assets/icons/instagram-blue.svg';
import LinkedinBlueIcon from '../assets/icons/linkedin-blue.svg';
import WhatsappBlueIcon from '../assets/icons/whatsapp-blue.svg';


const SocialMedia = () => {
    /*
    //import FacebookBlueIcon from '../assets/icons/facebook-blue.png';
    //import TwitterBlueIcon from '../assets/icons/twitter-blue.png';
    <img className="facebook-blue-icon" src={FacebookBlueIcon} alt="Facebook" />
    <img className="twitter-blue-icon" src={TwitterBlueIcon} alt="Twitter" />
    */
    
    return (
        <div className="static-icons">
            <a href="https://wa.me/+5491123278925" target="_blank" rel="noopener noreferrer">
                <img className="whatsapp-blue-icon" src={WhatsappBlueIcon} alt="Whatsapp" />
            </a>
            <a href="https://www.instagram.com/yolo.latam/" target="_blank" rel="noopener noreferrer">
                <img className="instagram-blue-icon" src={InstagramBlueIcon} alt="Instagram" />
            </a>
            <a href="https://www.linkedin.com/company/yololatam/" target="_blank" rel="noopener noreferrer">
                <img className="linkedin-blue-icon" src={LinkedinBlueIcon} alt="Linkedin" />
            </a>
        </div>
    );
}

export default SocialMedia;