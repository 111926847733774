import "./AboutUs.css";
import CheckIcon from '../../assets/icons/check.png';
import StadisticIcon from '../../assets/icons/stadistic.png';
import ClickIcon from '../../assets/icons/click.png';
import PersonsIcon from '../../assets/icons/persons.png';

const AboutUs = () => {
    return (
        <div className="background-about-us" id="about">
            <div className="title-about-us">
                ¿Quiénes somos?
            </div>

            <div className="container-about-us">
                <div className="rectangle-about-us">
                    <img className="icon-about-us" src={CheckIcon} alt="Money" />
                    <div className="subtitle-about-us">Rápido, ágil y simple</div>
                    <p>En Yolo queremos brindarte
                        la mejor experiencia en tus
                        compras o ventas en cuotas </p>
                </div>
                <div className="rectangle-about-us">
                    <img className="icon-about-us" src={StadisticIcon} alt="User" />
                    <div className="subtitle-about-us">+20 años de trayectoria</div>
                    <p>Por ello confían en nosotros
                        las empresas más reconocidas
                        del sector retail</p>
                </div>
                <div className="rectangle-about-us">
                    <img className="icon-about-us" src={ClickIcon} alt="Calendary" />
                    <div className="subtitle-about-us">100% digital</div>
                    <p>Yolo es una empresa totalmente  digital, y por eso estamos  siempre disponibles para vos donde nos necesites.</p>
                </div>
                <div className="rectangle-about-us">
                    <img className="icon-about-us" src={PersonsIcon} alt="Hands" />
                    <div className="subtitle-about-us">Inclusión financiera</div>
                    <p>Creemos que es fundamental para el crecimiento, y por eso contamos con bajos requisitos para que puedas comprar y vender con nuestros créditos</p>
                </div>
            </div>
        </div>
    );
};

export default AboutUs;
