import "./Functioning.css";

const Functioning = () => {
    return (
        <div className="background-functoning" id="home">
            <div className="title-functioning">Vendé financiado en forma simple con Yolo</div>
            <div className="container-functioning">
                <div className="number-box">
                    <div className="text-box">
                        <div className="number-functioning">#1</div>
                        <div className="text-functioning">Ingresá a la<br />
                            plataforma con<br />
                            los datos del<br />
                            cliente<br /></div>
                    </div>
                    <div className="text-box">
                        <div className="number-functioning">#2</div>
                        <div className="text-functioning">Calificá <br />
                            crediticiamente<br /></div>
                    </div>
                    <div className="text-box">
                        <div className="number-functioning">#3</div>
                        <div className="text-functioning">Validá la identidad <br />
                            y aceptación del <br />
                            crédito del cliente<br /></div>
                    </div>
                    <div className="text-box">
                        <div className="number-functioning">#4</div>
                        <div className="text-functioning">Entregá el <br />
                            producto<br /></div>
                    </div>
                    <div className="text-box">
                        <div className="number-functioning">#5</div>
                        <div className="text-functioning">Yolo te abonará <br />
                            la compra del <br />
                            cliente en el <br />
                            plazo acordado<br /></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Functioning;