import "./Data.css";

const Data = () => {
    return (
        <div className="background-data" id="data">
            <div className="title-data">
                Somos el medio de pago<br />
                para hacer <span style={{ color: '#0BFF93' }}>crecer tus ventas</span>
            </div>

            <div className="container-data">
                <div className="rectangle-data">
                    <div className="subtitle-data">Sumá tu comercio</div>
                    <p>Nos integramos a tu estrategia, somos el mejor partner para tu tienda</p>
                </div>
                <div className="rectangle-data">
                    <div className="subtitle-data">No cobramos comisión</div>
                    <p>YOLO garantiza términos de financiamiento claros y transparentes, sin cargos ocultos</p>
                </div>
                <div className="rectangle-data">
                    <div className="subtitle-data">Sin riesgo</div>
                    <p>Yolo se ocupa de cobrar,
                        y vos te enfocás en vender</p>
                </div>
                <div className="rectangle-data">
                    <div className="subtitle-data">Sin costos ocultos</div>
                    <p>Sumás más clientes, con una potente herramienta
                        de ventas</p>
                </div>
                <div className="rectangle-data">
                    <div className="subtitle-data">Atención 24/7</div>
                    <p>Siempre tendremos atención comercial personalizada</p>
                </div>
                <div className="rectangle-data">
                    <div className="subtitle-data">Fidelizá a tus clientes</div>
                    <p>Te acompañamos con publicidad para aumentar la venta recurrente</p>
                </div>
            </div>
        </div>
    );
};

export default Data;
