import "./Navbar.css";
import YoloIcon from '../../assets/icons/yolo-green.png';
import UserIcon from '../../assets/icons/user-mobile.png';
import MenuIcon from '../../assets/icons/menu.png';
import { useRef, useState } from "react";
import useHover from "@react-hook/hover";
import { useLocation } from "react-router-dom";
import InstagramGreen from '../../assets/icons/instagram-green.png';
import LinkedinGreen from '../../assets/icons/linkedin-green.png';
import WhatsappGreen from '../../assets/icons/whatsapp-green.png';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

const pageLink = {
    default: {
        transition: 'opacity 1s, transform 1s',
    },
    Hovered: {
        filter: 'brightness(2)'
    }
};

const Navbar = () => {

    const linkContact = useRef(null);
    const location = useLocation().pathname;
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const isHoveredContact = useHover(linkContact);

    const handleScrollToContact = (e) => {
        e.preventDefault();
        document.querySelector('#contact').scrollIntoView({ behavior: 'smooth' });
    };
    const style = {
        position: 'absolute',
        top: '90px',
        right: '10px',
        width: 260,
        height: 420,
        display: 'flex',
        padding: 0,
        border: 'none',
        transform: 'translate(0, 0)'
    };
    return (
        <>
            <nav className="navbar" style={{ zIndex: '20' }}>
                <img className="yolo-icon" src={YoloIcon} alt="Yolo" />
                {
                    open ? <svg width='45px' height='45px' style={{ marginRight: '30px' }} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={handleClose} >
                        <path stroke="#0bff93" fill-rule="evenodd" clip-rule="evenodd" d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z" fill="#0bff93" />
                    </svg>
                        : <img className="menu-icon" src={MenuIcon} alt="Menu" onClick={handleOpen} />
                }
                <div className="desktop-menu">
                    <a href="/" className={`${location == '/' ? 'selected-nav-route' : 'nav-route'} `}>Home</a>
                    <a href="quiero-comprar" className={`${location == '/quiero-comprar' ? 'selected-nav-route' : 'nav-route'} `}>Quiero comprar</a>
                    <a href="quiero-vender" className={`${location == '/quiero-vender' ? 'selected-nav-route' : 'nav-route'} `}>Quiero vender</a>
                    <a href="quienes-somos" className={`${location == '/quienes-somos' ? 'selected-nav-route' : 'nav-route'} `}>¿Quienes somos?</a>
                    <a href="#contact" style={pageLink[isHoveredContact ? "Hovered" : "default"]} ref={linkContact} className="nav-route" onClick={handleScrollToContact}>Contacto</a>
                    <div className="access">  <img className="user-icon" src={UserIcon} alt="User" /> <span className="text-access">Acceso</span></div>
                </div>
            </nav>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                disableEnforceFocus={true}
                disableAutoFocus={true}
                style={{ zIndex: '10' }}
                className="modal"
            >
                <Box sx={style}>
                    <ul className="mobile-menu">
                        <a href="/" className={`${location == '/' ? 'selected-nav-route' : 'nav-route'} `}>Home</a>
                        <a href="quiero-comprar" className={`${location == '/quiero-comprar' ? 'selected-nav-route' : 'nav-route'} `}>Quiero comprar</a>
                        <a href="quiero-vender" className={`${location == '/quiero-vender' ? 'selected-nav-route' : 'nav-route'} `}>Quiero vender</a>
                        <a href="quienes-somos" className={`${location == '/quienes-somos' ? 'selected-nav-route' : 'nav-route'} `}>¿Quienes somos?</a>
                        <a href="#contact" style={pageLink[isHoveredContact ? "Hovered" : "default"]} ref={linkContact} className="nav-route" onClick={(e) => { handleScrollToContact(e); handleClose() }}>Contacto</a>
                        <div className="access">  <img className="user-icon" src={UserIcon} alt="User" /> <span className="text-access">Acceso</span></div>
                        <div>
                            <div className="social-media">
                                <a href="https://wa.me/+5491123278925" target="_blank" rel="noopener noreferrer">
                                    <img className="footer-wpp-green" src={WhatsappGreen} alt="Whatsapp" />
                                </a>
                                <a href="https://www.instagram.com/yolo.latam/" target="_blank" rel="noopener noreferrer">
                                    <img className="footer-ig-green" src={InstagramGreen} alt="Instagram" />
                                </a>
                                <a href="https://www.linkedin.com/company/yololatam/" target="_blank" rel="noopener noreferrer">
                                    <img className="footer-ln-green" src={LinkedinGreen} alt="Linkedin" />
                                </a>
                            </div>
                        </div>
                    </ul>
                </Box>
            </Modal>
        </>
    );
};

export default Navbar;

