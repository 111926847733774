import "./Contact.css";
import { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';

const Contact = () => {

    const [formData, setFormData] = useState({
        from_name: '',
        from_email: '',
        from_telephone: '',
        from_company: '',
        from_category: '',
        from_home: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_b6kilcd', 'template_xlwwlid', form.current, { publicKey: '8P1d-YTaRErDQ7xwv' })
            .then((result) => {
                console.log(result.text);
                alert('Mensaje enviado exitosamente');
            }, (error) => {
                console.log(error.text);
                alert('Error al enviar el mensaje');
            });
        setFormData({
            from_name: '',
            from_email: '',
            from_telephone: '',
            from_company: '',
            from_category: '',
            from_home: ''
        });
    };

    return (
        <div className="background-contact" id="contact">
            <h2>Contactanos</h2>
            <form ref={form} onSubmit={sendEmail}>
                <div className="form-row">
                    <input type="text" id="from_name" name="from_name" value={formData.from_name} onChange={handleChange} required placeholder="Nombre completo" />
                    <input type="email" id="from_email" name="from_email" value={formData.from_email} onChange={handleChange} required placeholder="E-mail" />
                </div>
                <div className="form-row">
                    <input type="tel" id="from_telephone" name="from_telephone" value={formData.from_telephone} onChange={handleChange} required placeholder="Teléfono" />
                    <input type="text" id="from_company" name="from_company" value={formData.from_company} onChange={handleChange} required placeholder="Empresa" />
                </div>
                <div className="form-row">
                    <input type="text" id="from_category" name="from_category" value={formData.from_category} onChange={handleChange} required placeholder="Rubro" />
                    <input type="text" id="from_home" name="from_home" value={formData.from_home} onChange={handleChange} required placeholder="Domicilio" />
                </div>
                <button type="submit" value="Send">Enviar</button>
            </form>
        </div>
    );
};

export default Contact;