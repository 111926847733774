import React, { useContext } from 'react';
import { MenuContext } from './MenuContext';

const BlurredContent = ({ children }) => {
  const { isMenuOpen } = useContext(MenuContext);

  return (
    <div className={isMenuOpen ? 'blurred' : ''}>
      {children}
    </div>
  );
};

export default BlurredContent;