import "./Benefits.css";
import YoloIcon from '../../assets/icons/yolo-green.png';
import MoneyIcon from '../../assets/icons/money.png';
import UserIcon from '../../assets/icons/user.png';
import CalendaryIcon from '../../assets/icons/calendary.png';
import HandsIcon from '../../assets/icons/hands.png';

const Benefits = () => {
    return (
        <div className="background-benefits" id="home">
            <div className="title-benefits">
                Beneficios de comprar con
                <img className="yolo-green-benefits" src={YoloIcon} alt="Yolo" />
            </div>

            <div className="container-benefits">
                <div className="rectangle-benefit">
                    <img className="icon-benefit" src={MoneyIcon} alt="Money" />
                    <p>Buscamos democratizar <br /> el acceso al crédito</p>
                </div>
                <div className="rectangle-benefit">
                    <img className="icon-benefit" src={UserIcon} alt="User" />
                    <p>Priorizamos la<br /> experiencia del usuario</p>
                </div>
                <div className="rectangle-benefit">
                    <img className="icon-benefit" src={CalendaryIcon} alt="Calendary" />
                    <p>Estamos online 24/7 para <br /> clientes y comercios</p>
                </div>
                <div className="rectangle-benefit">
                    <img className="icon-benefit" src={HandsIcon} alt="Hands" />
                    <p>Nuestros partners son<br /> líderes en el mercado retail</p>
                </div>
            </div>
        </div>
    );
};

export default Benefits;
